import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { TextField, Button, Typography, Container, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

function App() {
  const [formData, setFormData] = useState({
    notification: {
      title: '',
      body: '',
      image: '',
    },
    data: {
      type: 'event',
      url_video: '',
      message: '',
      url_event: '',
      url_info: ''
    },
    topic: 'all',
  });

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse(null);
    try {
      const res = await axios.post('https://release.apirest.dev/api/v1/notifications/send', formData);
      setResponse(res.data);
      console.log('Response:', res.data);
    } catch (error) {
      setResponse({ error: 'An error occurred' });
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const align = { textAlign: 'left' };

  return (
    <div className="App">
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>Notification</Typography>
          <TextField
            required
            fullWidth
            label="Title"
            name="notification.title"
            value={formData.notification.title}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            multiline
            rows={4}
            label="Body"
            name="notification.body"
            value={formData.notification.body}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />

          <TextField
            required
            fullWidth
            label="Image URL"
            name="notification.image"
            value={formData.notification.image}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />

          <TextField
            
            fullWidth
            label="URL link saber más"
            name="data.url_info"
            value={formData.data.url_info}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />

          {formData.notification.image && (
            <Box sx={{ mt: 2 }}>
              <img src={formData.notification.image} alt="Preview" style={{ width: '100%' }} />
              <div style={align}>
                <Typography variant="h5" gutterBottom>{formData.notification.title}</Typography>
                <Typography variant="body1" gutterBottom>{formData.notification.body}</Typography>
              </div>
            </Box>
          )}

          <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Enviar'}
          </Button>
        </Box>
        {response && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Response</Typography>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </Box>
        )}
      </Container>
    </div>
  );
}

export default App;
